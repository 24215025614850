import axios from 'axios'
const restPath = process.env.GATSBY_APIURL

export const submitContact = (contactFields) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(restPath + '/contact', contactFields,
          {headers: {'Content-Type': 'application/json'}})
        .then(function(data) {
          if(data.data){
            resolve(data.data)
          }else{
            var dataError = new Error('Malformed Response')
            reject(dataError)
          }
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
