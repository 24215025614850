import React from 'react'
import HomePage from '../components/HomePage'

const App = ({data, pageContext, location}) => {
  return(
    <HomePage fsOnly='false' />
  )
}

export default App
