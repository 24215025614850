import React, { Component } from 'react'
import Layout from "../layout"
import Seo from '../seo'
import {
  Button
} from '@mui/material'
import FormPlusLogo from '../../styles/img/form-plus-logo-3.svg'
import FSVideoReplacement from '../../styles/img/formplus_configurator_white.gif'
import ConfigureYourProducts from '../../styles/img/configure-your-products.svg'
import SeeYourDesignInContext from '../../styles/img/see-your-design-in-context.svg'
import CreateAndManageProjects from '../../styles/img/create-and-manage-projects.svg'
import ContactForm from './contact-form'
import {
  getCurrentAuthUser
} from '../../utils/auth'
import { isBrowser, navigate } from '../../utils/misc'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productId: '65bb6e70-9fb0-0139-24c0-5afec72e9506',
      productDefault: 'SBKNI-72-B-CU-NAT-ALF-PC/ALU-0A-SFM',
      design: null,
      prevDesign: null,
      productSelected: null,
      product: null,
      selectors: null,
      productInit: null,
      panel1: true,
      panel2: true,
      metaData: null,
      successMsg: '',
      loading: false,
      deleteDialogOpen: false,
      loggedInUser: null
    }
  }

  componentDidMount(){
    getCurrentAuthUser().then(function(loggedInUser){
      this.setState({
        loggedInUser: loggedInUser
      })
    }.bind(this))
  }

  getProduct = (configuratorId) => {
    let foundProduct = this.props.products.find((product)=>{
      return product.node.frontmatter.configurator_id === configuratorId
    })
    if(foundProduct){
      foundProduct = foundProduct.node
    }
    return foundProduct
  }

  handleGoToDashboard = () => {
    if(isBrowser){
      navigate('/dashboard')
    }
  }

  render() {
    return(
      <Layout hideHeader={true}>
        <Seo title="Form Plus" />
        <div className='home-page'>
          <div className='hero home-page-first'>
            <div className='hero-inner'>
              <div className='home-page-first-left'>
                <img src={FormPlusLogo} alt="Form+ Logo" />
                <p>Quickly configure site furniture and lighting products,
                visualize designs in any setting, and bridge the gap between
                virtual and real worlds with AR technology.</p>
                <Button variant="contained"
                onClick={this.handleGoToDashboard}>
                {this.state.loggedInUser ?  'Go to Dashboard' : 'Get Started'}
                </Button>
              </div>
              <img id='background-video' src={FSVideoReplacement} alt="Form+ Configurator" />
            </div>
          </div>
          <div className='hero'>
            <div className='hero-inner'>
              <h2 className='center'>Augmented Configuration <span className="">+</span> Project Management</h2>
              <div className='thirds'>
                <div>
                  <img src={ConfigureYourProducts} alt="Configure your products" />
                  <h3>Configure Your Products</h3>
                  <p>Take creative control as you explore design, material and
                  finish options and configure products to meet your
                  project-specific needs.</p>
                </div>
                <div>
                  <img src={SeeYourDesignInContext} alt="See your design in context" />
                  <h3>See Your Design In Context</h3>
                  <p>Use the integrated Augmented Reality function to visualize
                  your design in any indoor or outdoor environment.</p>
                </div>
                <div>
                  <img src={CreateAndManageProjects} alt="Create and manage products" />
                  <h3>Create And Manage Projects</h3>
                  <p>Add designs to one or more projects for future reference
                  and editing, and share them with your local F+S representative
                  for accurate pricing.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='hero how-it-works' id='demo'>
            <div className='hero-inner'>
              <div className='left'>
                <h2>How It Works</h2>
                <ol>
                  <li>
                    <h4>Select a Product</h4>
                    <p>Click the ... icon to see a full list of F+S products.
                    Browse everything from bike racks to bollards. </p>
                  </li>
                  <li>
                    <h4>Customize Your Design</h4>
                    <p>Use the toolbar to explore materials and configurations.
                    Direct access to the F+S product portfolio gives you
                    endless opportunities.</p>
                  </li>
                  <li>
                    <h4>See It In Real Life</h4>
                    <p>Take the guesswork out of design with high quality
                    renderings, and bring your work to life with AR technology.
                    </p>
                  </li>
                </ol>
              </div>
              <div className='right' style={{
                position: 'relative'
              }}>
              <iframe 
              title='Form+ Viewer'
              src='/view/65bb6e70-9fb0-0139-24c0-5afec72e9506'
              style={{
                width: '100%',
                height: '100%',
                minHeight: '90vh',
                border: 'solid 1px'
              }}
              />
              </div>
            </div>
          </div>
          <div className='hero contact-us' id='contact-us'>
            <div className='hero-inner'>
              <h2 className='center'>Want More Info?</h2>
              <p className='center'>Send us your questions!</p>
              <ContactForm />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
