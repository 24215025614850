import React, { Component } from 'react'
import ContactForm from '../Form/more-info'
import {
  Alert,
  Snackbar
} from '@mui/material'
import { isLoggedIn } from '../../utils/auth'
import { getUserInfo } from '../../utils/user'
import { submitContact } from '../../utils/contact'
const isBrowser = typeof window !== `undefined`

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      panel1: true,
      previousPassword: '',
      newPassword: '',
      errMsg: '',
      successMsg: '',
      userInfo: null,
      contactInfo: null,
      viewPasswordChange: false,
      viewUserEdit: false,
      formSubmitted: false
    }
  }


  componentDidMount(){
    this.handleGetUserInfo()
  }

  handleGetUserInfo = () => {
    this.setState({
      loading: true
    })
    isLoggedIn()
    .then(user=>{
      if(user){
        getUserInfo()
        .then(info=>{
          let contactInfo = {}
          contactInfo.name = info?.name?.first + ' ' + info?.name?.last
          contactInfo.email = info?.email
          contactInfo.phone = info?.phone
          this.setState({
            userInfo: info,
            contactInfo: contactInfo,
            loading: false
          })
        })
        .catch(err=>{
          this.setState({
            loading: false
          })
        })
      }
    })
    .catch(err=>{
      this.setState({
        loading: false
      })
    })
  }

  handleContactInfoUpdate = (e) => {
    let contactInfo = this.state.contactInfo
    contactInfo[e.target.name] = e.target.value
    this.setState({
      contactInfo: contactInfo
    })
  }

  handleContactFormSubmit = (e) => {
    e.preventDefault()
    if(isBrowser){
      submitContact(this.state.contactInfo)
      .then((data)=>{
        this.setState({
          successMsg: 'Success',
          formSubmitted: true
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          errMsg: error.toString()
        })
      })
    }
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  render() {
    let contactInfo = this.state.contactInfo
    return(
      <>
        {!this.state.formSubmitted &&
          <ContactForm
          contactInfo={contactInfo}
          name={contactInfo?.name}
          company={contactInfo?.company}
          phone={contactInfo?.phone}
          email={contactInfo?.email}
          message={contactInfo?.message}
          handleSubmit={this.handleContactFormSubmit}
          handleUpdate={this.handleContactInfoUpdate}
          />
        }
        {this.state.formSubmitted &&
          <div>
            <h4>Thanks! We got your message</h4>
            <p>Your local representative will contact you in the next few days.
            All correspondence will be sent to either the phone number or email you provided.
            Please be aware some emails may get diverted to spam folders.
            In the meantime, feel free to configure and explore more F+S products.</p>
          </div>
        }
        <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
          <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
            {(this.state.errMsg ? this.state.errMsg : '')}
          </Alert>
        </Snackbar>
        <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
          <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
            {(this.state.successMsg ? this.state.successMsg : '')}
          </Alert>
        </Snackbar>
      </>
    )
  }
}

export default Contact
