import React from 'react'
import {
  Button,
  TextField,
  FormControl} from '@mui/material'

export default(({ handleSubmit, handleUpdate, disableSubmit, name, company, email, phone, message, history }) => (
  <form
    name="Contact"
    className="entry-form contact-form"
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
    enctype="multipart/form-data"
    netlify
    data-netlify="true"
  >
    <input type="hidden" name="form-name" value="Contact" />
    <div className="row">
      <div className="col-sm-6">
        <FormControl sx={{width: '100%'}}>
          <TextField
           required
           id="name"
           name="name"
           value={name}
           label="Name"
           variant="standard"
           InputLabelProps={{
            shrink: true,
           }}
           onChange={handleUpdate}
          />
        </FormControl>
        <FormControl sx={{width: '100%'}}>
          <TextField
           required
           id="email"
           name="email"
           value={email}
           placeholder="email@domain.com"
           label="Email"
           variant="standard"
           InputLabelProps={{
            shrink: true,
           }}
           onChange={handleUpdate}
          />
        </FormControl>
        <FormControl sx={{width: '100%'}}>
          <TextField
           id="phone"
           name="phone"
           value={phone}
           placeholder="Phone Number"
           label="Phone Number"
           variant="standard"
           InputLabelProps={{
            shrink: true,
           }}
           onChange={handleUpdate}
          />
        </FormControl>
      </div>
      <div className="col-sm-6">
        <FormControl sx={{width: '100%'}}>
          <TextField
           required
           id="message"
           name="message"
           value={message}
           placeholder="Type your message here..."
           label="Message"
           multiline={true}
           minRows={10}
           variant="standard"
           InputLabelProps={{
            shrink: true,
           }}
           onChange={handleUpdate}
          />
        </FormControl>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <input name="file" type="file" hidden/>
      </div>
      <div className="col-sm-6 submit-area">
        <Button
          type="submit"
          variant="contained"
          disabled={disableSubmit}>
            Submit
        </Button>
      </div>
    </div>
  </form>
))
